import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/foundation-flex.css'
import '@/assets/css/estilos-gerais.css'
import '@/assets/css/reseta-css.css'

import axios from './plugins/axios.js';
import store from '@/store/index.js'

Vue.config.productionTip = false;

Vue.use(axios);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
