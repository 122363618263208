export default {
  state: () => ({
    showPopupSelectProject: false,
    allUserProjects: [],
    currentProject: false,
    project: {},
    projectTasks: [],
    projectUpdates: [],
    projectsTeam: []
  }),
  mutations: {
    SET_SHOWPOPUPSELECTPROJECT(state, payload) {
      state.showPopupSelectProject = payload;
    },
    SET_ALLUSERPROJECTS(state, payload) {
      state.allUserProjects = payload;
    },
    SET_CURRENTPROJECT(state, payload) {
      localStorage.setItem('currentProject', JSON.stringify(payload));
      state.currentProject = payload;
    },
    SET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_PROJECTTASKS(state, payload) {
      state.projectTasks = payload;
    },
    SET_PROJECTUPDATES(state, payload) {
      state.projectUpdates = payload;
    },
    SET_PROJECTSTEAM(state, payload) {
      state.projectsTeam = payload;
    }
  },
  actions: {
    fetchAllUserProjects({ commit }) {
      return (
        this._vm.$axios
          .get('/usuarios/projetos')
          .then(({ data }) => {
            commit(
              'SET_ALLUSERPROJECTS', 
              data.map(
                project => { 
                  return {
                    ...project, 
                    selected: false, 
                    available: true,
                    label: project.nome,
                    className: ''
                  }
                }
              )
            );
            return data;
          })
          .catch(err => {
            return err;
          })
      );
    },
    fetchProjectsTeam({ commit }, id) {
      this._vm.$axios
        .get(`/projetos/${id}/equipe`)
        .then(({ data }) => {
          commit('SET_PROJECTSTEAM', data);
          return data;
        })
        .catch(err => {
          return err;
        })
    },
    fetchProjectById({ commit }, id) {
      return (
        this._vm.$axios
          .get(`/projetos/${id}`)
          .then(({ data }) => {
            commit('SET_PROJECT', data);
            return data;
          })
          .catch(err => {
            return err;
          })
      );
    },
    fetchProjectTasksById({ commit }, id) {
      return (
        this._vm.$axios
          .get(`/projetos/${id}/tarefas?limit=100`)
          .then(({ data }) => {
            commit('SET_PROJECTTASKS', data.data);
            return data;
          })
          .catch(err => {
            return err;
          })
      );
    },
    fetchProjectUpdates({ commit }, id) {
      return this._vm.$axios.get(`/projetos/${id}/atualizacoes?limit=100`)
        .then(({ data }) => {
          commit('SET_PROJECTUPDATES', data.data);
        })
        .catch(err => err);
    },
    viewProjectTask(context, { project, task }) {
      return this._vm.$axios.post(`/projetos/${project}/tarefas/${task}/visualizacao`)
        .then(({ data }) => data)
        .catch(err => err);
    },

    updateProjectTask(context, { project, task, payload }) {
      return this._vm.$axios.patch(`/projetos/${project}/tarefas/${task}/status`, payload)
        .then(({ data }) => data)
        .catch(err => err);
    },
    updateProjectStep(context, { project, payload }) {
      return this._vm.$axios.put(`/projetos/${project}/etapas`, payload)
        .then(({ data }) => data)
        .catch(err => err);
    }
  },
  getters: { }
}