export default {
  state: () => ({
    myAccess: [],
    isAuthorized: false,
  }),
  mutations: {
    SET_AUTHORIZED(state, payload) {
      state.isAuthorized = payload;
    },
    SET_MYACESS(state, payload) {
      state.myAccess = payload;
    },
  },
  actions: {
    fetchMyAccess({ commit }) {
      return (
        this._vm.$axios
          .get('/usuarios/meus-acessos')
          .then(({ data }) => {
            commit('SET_AUTHORIZED', true);
            commit('SET_MYACESS', data);
            
            const currentProject = localStorage.getItem('currentProject');
            if (currentProject) {
              commit('SET_CURRENTPROJECT', JSON.parse(currentProject));
            }

            return data;
          })
          .catch(err => {
            commit('SET_AUTHORIZED', false);
            return err;
          })
      );
    },
    logoutUser({ commit }) {
      commit('SET_CURRENTPROJECT', false);
      return this._vm.$axios.post('/usuarios/logout')
        .catch(err => {
          return err;
        });
    }
  },
  getters: { }
}