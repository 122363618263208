<template>
  <div id="layout-main">
    <LayoutError v-if="$route.name === 'NotFound'"/>
    <LayoutLogin 
      v-else-if="
        $route.name === 'Login' || 
        $route.name === 'RecoverPassword' ||
        $route.name === 'NewPassword'
      "
    />
    <LayoutPanel v-else-if="protectedRoute" />
  </div>
</template>
<script>
export default {
  name: 'LayoutMain',
  components: {
    LayoutError: () => import(/* webpackChunkName: "LayoutError" */ "@/layouts/LayoutError.vue"),
    LayoutLogin: () => import(/* webpackChunkName: "LayoutLogin" */ "@/layouts/LayoutLogin.vue"),
    LayoutPanel: () => import(/* webpackChunkName: "LayoutDefault" */ "@/layouts/LayoutPanel.vue"),
},
  computed: {
    protectedRoute() {
      return this.$store.state.userModule.isAuthorized
    }
  }
}
</script>

<style scoped>

</style>