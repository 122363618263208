export default {
  state: () => ({
    notifications: [],
  }),
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    fetchProjectsNotificationsById({ commit }, id) {
      return this._vm.$axios.get(`/projetos/${id}/notificacoes?limit=10`)
        .then(async ({ data }) => {
          const projectsNotifications = await Promise.all(
            data.data.map(async (nofitication) => {
              const { data: userData } = await this._vm.$axios.get(`/usuarios/${nofitication.autor.id}`);
              return {
                ...nofitication,
                author: {
                  ...nofitication.autor,
                  responsibility: userData.cargo,
                  avatar: userData.imagem
                }
              }
            })
          );

          commit('SET_NOTIFICATIONS', projectsNotifications);
        })
        .catch(err => {
          return err;
        });
    },
    postViewNotification(context, id) {
      return this._vm.$axios.post(`/notificacoes/${id}/visualizacao`)
        .catch(err => err);
    }
  },
  getters: { }
}