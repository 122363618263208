export default {
  state: () => ({
    loading: false,
    contents: [],
  }),
  mutations: {
    SET_CONTENTS(state, payload) {
      state.contents = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    fetchContentByCategory({ commit }, category) {
      commit('SET_LOADING', true);
      return (
        this._vm.$axios
          .get(`/conteudos?limit=10&categoria=${category}`)
          .then(({ data }) => {
            commit('SET_CONTENTS', data.data);
            commit('SET_LOADING', false);
            return data;
          })
          .catch(err => {
            commit('SET_LOADING', false);
            return err;
          })
      );
    },
  },
  getters: { }
}