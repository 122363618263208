import store from "../store";
export default async function userAuthorization(to, from, next) {
  await store.dispatch('fetchMyAccess');
  if (!store.state.userModule.isAuthorized && to.name === 'RecoverPassword') {
    next();
  } else if (
    (
      !store.state.userModule.isAuthorized ||
      !store.state.projectModule.currentProject
    ) &&
    to.name !== 'Login'
  ) {
    next('/login');
  }  else if (
    store.state.userModule.isAuthorized && 
    store.state.projectModule.currentProject && 
    to.name === 'Login'
  ) {
    next('/');
  } else {
    next();
  }
}