
<template>
  <div id="app">
    <LayoutMain />

    <CommonSelectProject 
      v-if="$store.state.projectModule.showPopupSelectProject"
    />
  </div>
</template>

<script>
import LayoutMain from './layouts/LayoutMain.vue';
export default {
  name: 'App',
  components: {
    LayoutMain,
    CommonSelectProject: () => import('@/components/common/CommonSelectProject.vue'),
  }
}
</script>

<style>
#app.login {
  overflow: hidden;
}
.fundoEscuro {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
