import axios from 'axios';

export default {
  install(Vue) {
    const myAxios = axios.create({
      baseURL: 'https://minhaloja.wapstore.com.br/api',
      headers: {
        Accept: 'application/json'
      }
    });

    myAxios.interceptors.request.use(config => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${localStorage.token}`
        }
      }
    });
    myAxios.interceptors.response.use(
      response => {
        if (response.data && response.data.token) {
          localStorage.setItem('token', response.data.token);
        }
        return response;
      }, 
      err => Promise.reject(err.response)
    );

    Vue.prototype.$axios = myAxios;
  }
}