import Vue from 'vue';
import VueRouter from 'vue-router';

import userAuthorization from '../middleware/userAuthorization';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "LayoutError" */ '@/layouts/LayoutError.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "ViewLogin" */ '@/views/ViewLogin.vue'),
  },
  {
    path: '/recuperar-senha',
    name: 'RecoverPassword',
    component: () => import(/* webpackChunkName: "ViewRecoverPassword" */ '@/views/ViewRecoverPassword.vue'),
  },
  {
    path: '/nova-senha',
    name: 'NewPassword',
    component: () => import(/* webpackChunkName: "ViewNewPassword" */ '@/views/ViewNewPassword.vue'),
  },
  {
    path: '/painel',
    alias: '/',
    name: 'Panel',
    component: () => import(/* webpackChunkName: "ViewPanel" */ '@/views/ViewPanel.vue'),
  },
  {
    path: '/painel/checklist',
    name: 'Checklist',
    component: () => import(/* webpackChunkName: "ViewChecklist" */ '@/views/ViewChecklist.vue') 
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(userAuthorization);

export default router