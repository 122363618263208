
import Vue from 'vue';
import Vuex from 'vuex';

import userModule from './user';
import projectModule from'./project';
import contentModule from './content'
import notificationModule from './notification';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userModule,
    projectModule,
    contentModule,
    notificationModule
  },
  state: {
    fundoEscuro: false
  },
  mutations: {
    toggleFundoEscuro(state, payload) {
      state.fundoEscuro = payload;
    },
  }
})
